import React from 'react'
//import { Link } from 'gatsby'

import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>
    <h1>What Is Dragon Boating?</h1>
    <p>Dragon boating is believed to be effective in preventing/reducing the risk of lymphedema post breast cancer.
      The benefits of exercise/ open air/ camerarderie etc, also contribute to the emotional wellbeing of the survivor. 
    </p>
    <p>It is a fun sport where teams of people paddle in time to move the boat across the water. It is a team sport
     in the truest sense. If one member of the team in not in time with the rest the boat will not move efficiently. Also if one 
     member of the team is paddling at full power but their partner in the same row is not the boat will actually move sideways! 
     All members need to paddle in a synchronised movement with each other and at the same power level.
    </p>
  </Layout>
)

export default IndexPage
